import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
  CALENDAR_EVENT,
  CALENDAR_EVENT_API_URL,
  GET_ALL_CALENDAR_EVENT_API_URL,
} from "constants/AppConstants";
import CommonService from "services/CommonService/CommonService";
import IntlMessage from "components/util-components/IntlMessage";
const setLocale = (localeKey, isLocaleOn = true) =>
  isLocaleOn ? <IntlMessage id={localeKey} /> : localeKey.toString();

export const initialState = {
  CalendarEventErrors: {},
  permission: [],
  CalendarEventShowMessage: null,
  CalendarEventResult: [],
  CalendarEventButtonSpinner: false,
  CalendarEventTableLoading: true,
  CalendarEventAddDrawer: false,
  CalendarEventEditData: {},
  CalendarEventButtonAndModelLabel: setLocale("calendarEvent.add"),
  tablePagination: {
    current: 1,
    pageSize: 10,
    total: 0,
  },
  sorting: {},
  filter: {},
  ViewCalendarEventData: [],
  ViewCalendarEventLoader: true,
  colorPicker: "",
  DrawerStatus: 0,
};

export const createCalendarEvent = createAsyncThunk(
  "createCalendarEvent",
  async (data) => {
    try {
      const response = await CommonService.createAndUpdate(
        data,
        CALENDAR_EVENT_API_URL
      );
      return response;
    } catch (err) {
      throw new Error(JSON.stringify(err.response.data.errors)); // Throw an error with the server response errors
    }
  }
);

export const getCalendarEvent = createAsyncThunk(
  "getCalendarEvent",
  async (data) => {
    try {
      const response = await CommonService.getAllPost(
        data,
        GET_ALL_CALENDAR_EVENT_API_URL
      );
      return response;
    } catch (err) {
      throw new Error(JSON.stringify(err.response.data.errors)); // Throw an error with the server response errors
    }
  }
);

export const viewCalendarEvent = createAsyncThunk(
  "viewCalendarEvent",
  async (id) => {
    try {
      const response = await CommonService.showOne(id, CALENDAR_EVENT_API_URL);
      return response;
    } catch (err) {
      throw new Error(JSON.stringify(err.response.data.errors)); // Throw an error with the server response errors
    }
  }
);

export const deleteCalendarEvent = createAsyncThunk(
  "deleteCalendarEvent",
  async (data) => {
    try {
      const response = await CommonService.deleteOne(
        data,
        CALENDAR_EVENT_API_URL
      );
      return response;
    } catch (err) {
      throw new Error(JSON.stringify(err.response.data.errors)); // Throw an error with the server response errors
    }
  }
);

export const manageCalendarEventSlice = createSlice({
  name: CALENDAR_EVENT,
  initialState,
  reducers: {
    onCloseError: (state, action) => {
      state.CalendarEventErrors = {};
    },
    CalendarEventAddDrawerStatus: (state, action) => {
      // state.CalendarEventErrors = {};
      state.DrawerStatus = action.payload.errorStatus;
      state.CalendarEventAddDrawer = action.payload.status;
      state.CalendarEventEditData = {};
      state.CalendarEventButtonAndModelLabel = setLocale("calendarEvent.add");
    },
    CalendarEventEditWithDrawerStatus: (state, action) => {
      // state.CalendarEventErrors = {};
      state.CalendarEventAddDrawer = true;
      state.DrawerStatus = action.payload.errorStatus;
      state.CalendarEventEditData = action.payload.data;
      state.colorPicker = action.payload.data.color_code;
      state.CalendarEventButtonAndModelLabel = setLocale("calendarEvent.edit");
    },
    updateSortFilters: (state, action) => {
      state.filter = action.payload.filter;
      state.sorting = action.payload.sorting;
    },
    setColumnSearch: (state, action) => {
      state.filter = action.payload;
    },
    setColorPicker: (state, action) => {
      state.colorPicker = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(createCalendarEvent.pending, (state, action) => {
        state.DrawerStatus = 0;
        state.CalendarEventButtonSpinner = true;
      })
      .addCase(createCalendarEvent.fulfilled, (state, action) => {
        state.DrawerStatus = 0;
        state.CalendarEventButtonSpinner = false;
        state.CalendarEventErrors = {};
      })
      .addCase(createCalendarEvent.rejected, (state, action) => {
        state.DrawerStatus = 1;
        state.CalendarEventShowMessage = true;
        state.CalendarEventButtonSpinner = false;
        state.CalendarEventErrors = JSON.parse(action.error.message); // Parse the error messages and store them in the state
      })
      .addCase(getCalendarEvent.pending, (state, action) => {
        state.CalendarEventButtonSpinner = true;
        state.CalendarEventTableLoading = true;
      })
      .addCase(getCalendarEvent.fulfilled, (state, action) => {
        state.CalendarEventButtonSpinner = false;
        state.CalendarEventTableLoading = false;
        state.CalendarEventResult = action.payload;
        state.tablePagination = {
          ...state.tablePagination,
          total: action.payload.total,
          current: action.payload.current_page,
          pageSize: action.payload.per_page,
        };
      })
      .addCase(getCalendarEvent.rejected, (state, action) => {
        state.CalendarEventShowMessage = true; // Set the showMessage flag to display the errors
        state.CalendarEventButtonSpinner = false;
        state.CalendarEventTableLoading = false;
        state.CalendarEventErrors = JSON.parse(action.error.message); // Parse the error messages and store them in the state
      })
      .addCase(viewCalendarEvent.pending, (state, action) => {
        state.ViewCalendarEventLoader = true;
        state.ViewCalendarEventData = [];
      })
      .addCase(viewCalendarEvent.fulfilled, (state, action) => {
        state.ViewCalendarEventLoader = false;
        state.ViewCalendarEventData = action.payload;
      })
      .addCase(viewCalendarEvent.rejected, (state, action) => {
        state.ViewCalendarEventLoader = false;
        state.ViewCalendarEventData = [];
      })
      .addCase(deleteCalendarEvent.pending, (state, action) => {})
      .addCase(deleteCalendarEvent.fulfilled, (state, action) => {})
      .addCase(deleteCalendarEvent.rejected, (state, action) => {});
  },
});

export const {
  onCloseError,
  setColorPicker,
  setColumnSearch,
  CalendarEventAddDrawerStatus,
  CalendarEventEditWithDrawerStatus,
  updateSortFilters,
} = manageCalendarEventSlice.actions;

export default manageCalendarEventSlice.reducer;
