import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { THEME_CONFIG } from 'configs/AppConfig'
import {THEME_SETTING, THEME_SETTING_API_URL, GET_ALL_THEME_SETTING_API_URL} from 'constants/AppConstants';
import { USER_INFORMATION,USER_ALL_INFORMATION_ORGANIZATION_WISE } from "constants/AuthConstant";

import CommonService from 'services/CommonService/CommonService';

const userTheme = JSON.parse(localStorage.getItem(USER_INFORMATION));
export const initialState = THEME_CONFIG

export const updateThemeSetting = createAsyncThunk('updateThemeSetting', async (data) => {  
  try {
    const response = await CommonService.postData(data, THEME_SETTING_API_URL);
    return response;
  } catch (err) {
    throw new Error(JSON.stringify(err.response.data.errors)); // Throw an error with the server response errors
  }
})

export const themeSlice = createSlice({
	name: 'theme',
	initialState,
	reducers: {
        toggleCollapsedNav: (state, action) => {
			state.navCollapsed = action.payload
		},
        onNavStyleChange: (state, action) => {
			state.sideNavTheme = action.payload
		},
        onLocaleChange: (state, action) => {
			state.locale = action.payload
		},
        onNavTypeChange: (state, action) => {
			state.navType = action.payload
		},
        onTopNavColorChange: (state, action) => {
			state.topNavColor = action.payload
		},
        onHeaderNavColorChange: (state, action) => {
			state.headerNavColor = action.payload
		},
        onMobileNavToggle: (state, action) => {
			state.mobileNav = action.payload
		},
        onSwitchTheme: (state, action) => {
			state.currentTheme = action.payload
			if (userTheme) {
				userTheme.theme_setting.current_theme = action.payload
				localStorage.setItem(USER_INFORMATION, JSON.stringify(userTheme));
			}
		},
        onDirectionChange: (state, action) => {
			state.direction = action.payload
		},
		onBlankLayout: (state, action) => {
			state.blankLayout = action.payload
		},
	},
})

export const { 
    toggleCollapsedNav,
    onNavStyleChange,
    onLocaleChange,
    onNavTypeChange,
    onTopNavColorChange,
    onHeaderNavColorChange,
    onMobileNavToggle,
    onSwitchTheme,
    onDirectionChange,
	onBlankLayout
} = themeSlice.actions

export default themeSlice.reducer