import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import {PERSONAL_INFORMATION, PERSONAL_INFORMATION_API_URL, GET_ALL_PERSONAL_INFORMATION_API_URL,UPDATE_USER_PROFILE_PICTURE, REMOVE_USER_PROFILE_PICTURE,  UPDATE_CURRENT_USER_PASSWORD,} from 'constants/AppConstants';
import { USER_ALL_INFORMATION_ORGANIZATION_WISE } from "constants/AuthConstant";
import CommonService from 'services/CommonService/CommonService';
import IntlMessage from "components/util-components/IntlMessage";
const setLocale = (localeKey, isLocaleOn = true) =>
  isLocaleOn ? <IntlMessage id={localeKey} /> : localeKey.toString();
const permission = JSON.parse(localStorage.getItem(USER_ALL_INFORMATION_ORGANIZATION_WISE));

export const initialState = {
  PersonalInformationErrors       : {},
  permission: permission?.personal_information ?? [],
  PersonalInformationShowMessage  : null,
  PersonalInformationResult       : [],
  PersonalInformationButtonSpinner : false,
  PersonalInformationTableLoading : true,
  PersonalInformationAddDrawer    : false,
  PersonalInformationEditData     : [],
  PersonalInformationButtonAndModelLabel  : setLocale('personalinformation.add'),
  tablePagination: {
        current: 1,
        pageSize: 10,
        total: 0,
    },
    sorting: {},
    filter: {},
    ViewPersonalInformationData: [],
    ViewPersonalInformationLoader: true,
    ViewUserData: [],
    UserShowMessage  : null,
    UserButtonSpinner : false,
    UserTableLoading : true,
    loading: false,
    userUpdatePasswordModel: false,

};

export const createPersonalInformation = createAsyncThunk(
    "createPersonalInformation",
  async (data) => {
    try {
      const response = await CommonService.createAndUpdate(data, PERSONAL_INFORMATION_API_URL);
      return response;
    } catch (err) {
      throw new Error(JSON.stringify(err.response.data.errors)); // Throw an error with the server response errors
    }
  }
);

export const getPersonalInformation = createAsyncThunk(
    "getPersonalInformation",
  async (data) => {
    try {
      const response = await CommonService.getAllPost(data, GET_ALL_PERSONAL_INFORMATION_API_URL);
      return response;
    } catch (err) {
      throw new Error(JSON.stringify(err.response.data.errors)); // Throw an error with the server response errors
    }
  }
);

export const setUserProfilePicture = createAsyncThunk('setUserProfilePicture', async (data) => {
    try {
      return await CommonService.postData(data, UPDATE_USER_PROFILE_PICTURE) 
    } catch (err) {
      throw new Error(JSON.stringify(err.response.data.errors))
    }
})

export const viewPersonalInformation = createAsyncThunk(
  "viewPersonalInformation",
  async (id) => {
    try {
      const response = await CommonService.showOne(id, PERSONAL_INFORMATION_API_URL);
      return response;
    } catch (err) {
      throw new Error(JSON.stringify(err.response.data.errors)); // Throw an error with the server response errors
    }
  }
);

export const deletePersonalInformation = createAsyncThunk(
  "deletePersonalInformation",
  async (data) => {
    try {
      const response = await CommonService.deleteOne(data, PERSONAL_INFORMATION_API_URL);
      return response;
    } catch (err) {
      throw new Error(JSON.stringify(err.response.data.errors)); // Throw an error with the server response errors
    }
  }
);
export const removeProfilePicture = createAsyncThunk('removeProfilePicture', async (data) => {
  try {
    return await CommonService.postData(data, REMOVE_USER_PROFILE_PICTURE) 
  } catch (err) {
    throw new Error(JSON.stringify(err.response.data.errors))
  }
});
export const updateCurrentUserPassword = createAsyncThunk('updateCurrentUserPassword', async (data) => {
  try {
    return await CommonService.postData(data,UPDATE_CURRENT_USER_PASSWORD);
  } catch (err) {
    throw new Error(JSON.stringify(err.response.data.errors))
  }
})

export const managePersonalInformationSlice = createSlice({
  name: PERSONAL_INFORMATION,
  initialState,
  reducers: {
    PersonalInformationAddDrawerStatus: (state, action) => {
      state.PersonalInformationErrors      = {};
      state.PersonalInformationAddDrawer   = action.payload;
      state.PersonalInformationEditData    = [];
      state.PersonalInformationButtonAndModelLabel = setLocale('personalinformation.add');
    },
    PersonalInformationEditWithDrawerStatus: (state, action) => {
      state.PersonalInformationErrors = {};
      state.PersonalInformationAddDrawer = true;
      state.PersonalInformationEditData = action.payload;
      state.PersonalInformationButtonAndModelLabel = setLocale('personalinformation.edit');
    },
    updateSortFilters: (state, action) => {
        state.filter = action.payload.filter;
        state.sorting = action.payload.sorting;
    },
    setUserUpdatePasswordModel: (state, action) => {      
      state.userUpdatePasswordModel = action.payload
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(createPersonalInformation.pending, (state, action) => {
        state.PersonalInformationButtonSpinner = true;
      }).addCase(createPersonalInformation.fulfilled, (state, action) => {
        state.PersonalInformationButtonSpinner = false;
        state.PersonalInformationErrors = {}
      }).addCase(createPersonalInformation.rejected, (state, action) => {
        state.PersonalInformationShowMessage = true;
        state.PersonalInformationButtonSpinner = false;
        state.PersonalInformationErrors = JSON.parse(action.error.message); // Parse the error messages and store them in the state
      }).addCase(getPersonalInformation.pending, (state, action) => {
        state.PersonalInformationButtonSpinner = true;
        state.PersonalInformationTableLoading = true;
      }).addCase(getPersonalInformation.fulfilled, (state, action) => {
        state.PersonalInformationButtonSpinner = false;
        state.PersonalInformationTableLoading = false;
        state.PersonalInformationResult = action.payload;
        state.tablePagination = {
            ...state.tablePagination,
            total: action.payload.total,
            current: action.payload.current_page,
            pageSize: action.payload.per_page
        };
      }).addCase(getPersonalInformation.rejected, (state, action) => {
        state.PersonalInformationShowMessage = true; // Set the showMessage flag to display the errors
        state.PersonalInformationButtonSpinner = false;
        state.PersonalInformationTableLoading = false;
        state.PersonalInformationErrors = JSON.parse(action.error.message); // Parse the error messages and store them in the state
      })
      .addCase(viewPersonalInformation.pending, (state, action) => {
        state.ViewPersonalInformationLoader = true;
        state.ViewPersonalInformationData = [];
      }).addCase(viewPersonalInformation.fulfilled, (state, action) => {
        state.ViewPersonalInformationLoader = false;
        state.ViewPersonalInformationData = action.payload;
      }).addCase(viewPersonalInformation.rejected, (state, action) => {
        state.ViewPersonalInformationLoader = false;
        state.ViewPersonalInformationData = [];
      })
      .addCase(deletePersonalInformation.pending, (state, action) => {

      }).addCase(deletePersonalInformation.fulfilled, (state, action) => {

      }).addCase(deletePersonalInformation.rejected, (state, action) => {

      })
      .addCase(setUserProfilePicture.pending, (state, action) => {

      }).addCase(setUserProfilePicture.fulfilled, (state, action) => {
        state.ViewUserData = action.payload.data
      }).addCase(setUserProfilePicture.rejected, (state, action) => {
        state.loading = false
        state.UserShowMessage = true
        state.UserButtonSpinner = false
        state.UserTableLoading = false
      }).addCase(removeProfilePicture.pending, (state, action) => {

      }).addCase(removeProfilePicture.fulfilled, (state, action) => {
        state.ViewUserData = action.payload.data
      }).addCase(removeProfilePicture.rejected, (state, action) => {
        state.loading = false
        state.UserShowMessage = true
        state.UserButtonSpinner = false
        state.UserTableLoading = false
      }).addCase(updateCurrentUserPassword.pending, (state, action) => {
        state.loading = true
      }).addCase(updateCurrentUserPassword.fulfilled, (state, action) => {
        state.loading = false
        state.userUpdatePasswordModel = false
      }).addCase(updateCurrentUserPassword.rejected, (state, action) => {
        state.loading = false
        state.UserShowMessage = true
        state.UserButtonSpinner = false
        state.UserTableLoading = false
        // state.UserErrors = JSON.parse(action.error.message)
      });
  },
});

export const {  setUserUpdatePasswordModel,PersonalInformationAddDrawerStatus, PersonalInformationEditWithDrawerStatus, updateSortFilters } = managePersonalInformationSlice.actions;

export default managePersonalInformationSlice.reducer;
