import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { STUDENT_GRADE_BOOK_SLICE } from 'constants/AppConstants';
import { 
    GET_CLASS_STUDENTS_FOR_GRADES,
    ADD_COURSE_GRADE_VATEGORY,
    ADD_STUDENT_OBTAIN_MARKS,
    FINALIZED_COURSE_GRADE_CATEGORY,
    PARENT_SINGLE_STUDENT_GRADE_DETAIL,
    VIEW_SINGLE_STUDENT_GRADE_DETAIL
 } from 'constants/student-grade-book/index';
import CommonService from 'services/CommonService/CommonService';

export const initialState = {
    loading: false,
    classStudentsForGrades:[],
    activeStudents:[],

    viewAs : "marks",
    loadSingleStudentGrading:false,
    studentCourseHeader:[],
    studentCourseBody:[],
    student:{},
  
}
export const getSingleStudentGradeDetail = createAsyncThunk('getSingleStudentGradeDetail', async (data) => {
    try {
        return await CommonService.getData(data, VIEW_SINGLE_STUDENT_GRADE_DETAIL);        
    } catch (err) {
        throw new Error(JSON.stringify(err.response.data.errors))
    }
})
export const getParentSingleStudentGradeDetail = createAsyncThunk('getParentSingleStudentGradeDetail', async (data) => {
    try {
        return await CommonService.getData(data, PARENT_SINGLE_STUDENT_GRADE_DETAIL);        
    } catch (err) {
        throw new Error(JSON.stringify(err.response.data.errors))
    }
  })
/** get ClassStudents for Grades */
export const getClassStudentsForGrades = createAsyncThunk('getClassStudentsForGrades', async (data) => {
    try {
        return await CommonService.postData(data, GET_CLASS_STUDENTS_FOR_GRADES);        
    } catch (err) {
        throw new Error(JSON.stringify(err.response.data.errors))
    }
})
/**add New courseGradeBookCategory By Teacher */
export const addCourseGradeBookCategory = createAsyncThunk('addCourseGradeBookCategory', async (data) => {
    try {
        return await CommonService.postData(data, ADD_COURSE_GRADE_VATEGORY);        
    } catch (err) {
        throw new Error(JSON.stringify(err.response.data.errors))
    }
})
/**add and update student obtained Marks */
export const addStudentObtainedMarks = createAsyncThunk('addStudentObtainedMarks', async (data) => {    
    try {
        return await CommonService.postData(data, ADD_STUDENT_OBTAIN_MARKS);        
    } catch (err) {
        throw new Error(JSON.stringify(err.response.data.errors))
    }
})
/** Finalized Grade course category */
export const finalizedCourseGradeCategory = createAsyncThunk('finalizedCourseGradeCategory', async (data) => {
    try {
        return await CommonService.postData(data, FINALIZED_COURSE_GRADE_CATEGORY);        
    } catch (err) {
        throw new Error(JSON.stringify(err.response.data.errors))
    }
})

export const studentGradeBookSlice = createSlice({
    name: STUDENT_GRADE_BOOK_SLICE,
    initialState,
    reducers: {
        setViewAs: (state, action) => {
            state.viewAs = action.payload
        },
    },
    extraReducers: (builder) => {
        builder  
        .addCase(getSingleStudentGradeDetail.pending, (state) => {
            state.loadSingleStudentGrading = true
            state.studentCourseHeader = []
            state.studentCourseBody = []
            state.student = {}
          }).addCase(getSingleStudentGradeDetail.fulfilled,(state,action)=>{
            state.loadSingleStudentGrading = false
            state.studentCourseHeader = action.payload.data?.studentCourseHeader
            state.studentCourseBody = action.payload.data?.studentCourseBody
            state.student = action.payload.data?.student
          }).addCase(getSingleStudentGradeDetail.rejected, (state) => {
            state.loadSingleStudentGrading = false
          })
          
        .addCase(getParentSingleStudentGradeDetail.pending, (state) => {
            state.loadSingleStudentGrading = true
            state.studentCourseHeader = []
            state.studentCourseBody = []
            state.student = {}
          }).addCase(getParentSingleStudentGradeDetail.fulfilled,(state,action)=>{
            state.loadSingleStudentGrading = false
            state.studentCourseHeader = action.payload.data?.studentCourseHeader
            state.studentCourseBody = action.payload.data?.studentCourseBody
            state.student = action.payload.data?.student
          }).addCase(getParentSingleStudentGradeDetail.rejected, (state) => {
            state.loadSingleStudentGrading = false
          })     
        /** get ClassStudents For Grades */
        .addCase(getClassStudentsForGrades.pending, (state) => {
            state.classStudentsForGrades = [];
            state.activeStudents = [];
        }).addCase(getClassStudentsForGrades.fulfilled,(state,action)=>{
            if(action.payload.data[0]?.id){
                state.classStudentsForGrades = action.payload.data
            }
            state.activeStudents = action.payload.data[0]?.students
        })       
    },
})

export const {
    setViewAs
} = studentGradeBookSlice.actions

export default studentGradeBookSlice.reducer