import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import {TEACHER_MESSAGE} from 'constants/AppConstants';
import fetch from '../../../auth/FetchInterceptor'


export const initialState = {
    loading: false,
    teacherMsg: [],
    
}
/** get ClassStudents for Grades */
export const getTeacherMsg = createAsyncThunk('getTeacherMsg', async (data) => {
    return fetch({
        url: '/get-message',
        method: 'post',
        data
    })
});

export const sentTeacherMsg = createAsyncThunk('sentTeacherMsg', async (data) => {
    return fetch({
        url: '/sent-message',
        method: 'post',
        data
    })
});



export const teacherMsgSlice = createSlice({
    name: TEACHER_MESSAGE,
    initialState,
    reducers: {

    },
    extraReducers: (builder) => {
        builder

            /** get Message between parent and teacher course wise */
            .addCase(getTeacherMsg.pending, (state) => {
                state.teacherMsg = [];
            })
            .addCase(getTeacherMsg.fulfilled, (state, action) => {
                
                if (action.payload.data) {
                    state.teacherMsg = action.payload.data;
                }
            })
            .addCase(sentTeacherMsg.fulfilled, (state, action) => {
                
            })
          
    },
})

export const {

} = teacherMsgSlice.actions

export default teacherMsgSlice.reducer