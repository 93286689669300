import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { STUDENT_COURSES, STUDENT_COURSES_API_URL, GET_ALL_STUDENT_COURSES_API_URL, GET_STUDENT_COURSES_API_URL, GET_STUDENT_COURSE_PLAN_API_URL, GET_TAGGED_CLASS_RESOURCES_API_URL } from 'constants/AppConstants';
import { USER_ALL_INFORMATION_ORGANIZATION_WISE } from "constants/AuthConstant";
import CommonService from 'services/CommonService/CommonService';
import IntlMessage from "components/util-components/IntlMessage";
const setLocale = (localeKey, isLocaleOn = true) =>
  isLocaleOn ? <IntlMessage id={localeKey} /> : localeKey.toString();
const permission = JSON.parse(localStorage.getItem(USER_ALL_INFORMATION_ORGANIZATION_WISE));

export const initialState = {
  StudentCoursesErrors: {},
  permission: permission?.student_courses ?? [],
  StudentCoursesShowMessage: null,
  StudentCoursesResult: [],
  StudentCoursesButtonSpinner: false,
  StudentCoursesTableLoading: true,
  StudentCoursesAddDrawer: false,
  StudentCoursesEditData: [],
  StudentCoursesButtonAndModelLabel: setLocale('studentcourses.add'),
  tablePagination: {
    current: 1,
    pageSize: 10,
    total: 0,
  },
  sorting: {},
  filter: {},
  ViewStudentCoursesData: [],
  classCourseScheduleDrawer: true,
  classCourseLessonDrawer: false,
  classCourseGradeDrawer: false,
  classCourseAssignmentDrawer: false,
  ViewStudentCoursesLoader: true,
  GuardianAllStudentCoursesErrors: {},
  GuardianAllStudentCoursesShowMessage: null,
  GuardianAllStudentCoursesResult: [],
  GuardianAllStudentCoursesButtonSpinner: false,
  GuardianAllStudentCoursesTableLoading: true,
  GuardianAllStudentCoursesAddDrawer: false,
  GuardianAllStudentCoursesEditData: [],
  studentCoursePlanResult: [],
  studentCoursePlanTableLoading: true,
  studentCoursePlanButtonSpinner: false,
  studentId: null,
  studentData: [],
  GuardianAllStudentCoursesButtonAndModelLabel: setLocale('studentcourses.add'),
  courseResources: [],
};

export const createStudentCourses = createAsyncThunk(
  "createStudentCourses",
  async (data) => {
    try {
      const response = await CommonService.createAndUpdate(data, STUDENT_COURSES_API_URL);
      return response;
    } catch (err) {
      throw new Error(JSON.stringify(err.response.data.errors)); // Throw an error with the server response errors
    }
  }
);

export const getStudentCourses = createAsyncThunk(
  "getStudentCourses",
  async (data) => {
    try {
      const response = await CommonService.getAllPost(data, GET_ALL_STUDENT_COURSES_API_URL);
      return response;
    } catch (err) {
      throw new Error(JSON.stringify(err.response.data.errors)); // Throw an error with the server response errors
    }
  }
);

export const viewStudentCourses = createAsyncThunk(
  "viewStudentCourses",
  async (id) => {
    try {
      const response = await CommonService.showOne(id, STUDENT_COURSES_API_URL);
      return response;
    } catch (err) {
      throw new Error(JSON.stringify(err.response.data.errors)); // Throw an error with the server response errors
    }
  }
);

export const deleteStudentCourses = createAsyncThunk(
  "deleteStudentCourses",
  async (data) => {
    try {
      const response = await CommonService.deleteOne(data, STUDENT_COURSES_API_URL);
      return response;
    } catch (err) {
      throw new Error(JSON.stringify(err.response.data.errors)); // Throw an error with the server response errors
    }
  }
);

export const getGuardianAllStudentCourses = createAsyncThunk(
  "getGuardianAllStudentCourses",
  async (data) => {
    try {
      const response = await CommonService.getAllPost(data, GET_STUDENT_COURSES_API_URL);
      return response;
    } catch (err) {
      throw new Error(JSON.stringify(err.response.data.errors)); // Throw an error with the server response errors
    }
  }
);

export const getStudentCoursePlan = createAsyncThunk(
  "getStudentCoursePlan",
  async (data) => {
    try {
      const response = await CommonService.getAllPost(data, GET_STUDENT_COURSE_PLAN_API_URL);
      return response;
    } catch (err) {
      throw new Error(JSON.stringify(err.response.data.errors)); // Throw an error with the server response errors
    }
  }
);

export const getCourseResources = createAsyncThunk(
  "getCourseResources",
  async (data) => {
    try {
      const response = await CommonService.getAllPost(data, GET_TAGGED_CLASS_RESOURCES_API_URL);
      return response;
    } catch (err) {
      throw new Error(JSON.stringify(err.response.data.errors)); // Throw an error with the server response errors
    }
  }
);


export const calenderEvent = createAsyncThunk('calenderEvent', async (data) => {
  //    console.log('you are in slice',data)
  return data;
});

export const manageStudentCoursesSlice = createSlice({
  name: STUDENT_COURSES,
  initialState,
  reducers: {
    StudentCoursesAddDrawerStatus: (state, action) => {
      state.StudentCoursesErrors = {};
      state.StudentCoursesAddDrawer = action.payload;
      state.StudentCoursesEditData = [];
      state.StudentCoursesButtonAndModelLabel = setLocale('studentcourses.add');
    },
    StudentCoursesEditWithDrawerStatus: (state, action) => {
      state.StudentCoursesErrors = {};
      state.StudentCoursesAddDrawer = true;
      state.StudentCoursesEditData = action.payload;
      state.StudentCoursesButtonAndModelLabel = setLocale('studentcourses.edit');
    },
    ClassCourseScheduleDrawerStatus: (state, action) => {
      state.classCourseScheduleDrawer = action.payload.status;
      state.classCourseSchedule = action.payload.data;

      state.classCourseLessonDrawer = false;
      state.classCourseGradeDrawer = false;
      state.classCourseAssignmentDrawer = false;
    },
    ClassCourseLessonDrawerStatus: (state, action) => {
      state.classCourseLessonDrawer = action.payload.status;

      state.classCourseScheduleDrawer = false;
      state.classCourseGradeDrawer = false;
      state.classCourseAssignmentDrawer = false;
    },
    ClassCourseGradeDrawerStatus: (state, action) => {
      state.classCourseGradeDrawer = action.payload.status;

      state.classCourseScheduleDrawer = false;
      state.classCourseLessonDrawer = false;
      state.classCourseAssignmentDrawer = false;
    },
    ClassCourseAssignmentDrawerStatus: (state, action) => {
      state.classCourseAssignmentDrawer = action.payload.status;

      state.classCourseScheduleDrawer = false;
      state.classCourseLessonDrawer = false;
      state.classCourseGradeDrawer = false;
    },
    updateSortFilters: (state, action) => {
      state.filter = action.payload.filter;
      state.sorting = action.payload.sorting;
    },
    setStudentId: (state, action) => {
      state.studentId = action.payload;
    },
    setStudentData: (state, action) => {
      state.studentData = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(createStudentCourses.pending, (state, action) => {
        state.StudentCoursesButtonSpinner = true;
      }).addCase(createStudentCourses.fulfilled, (state, action) => {
        state.StudentCoursesButtonSpinner = false;
        state.StudentCoursesErrors = {}
      }).addCase(calenderEvent.pending, (state, action) => {
      }).addCase(calenderEvent.fulfilled, (state, action) => {
      }).addCase(calenderEvent.rejected, (state, action) => {
      }).addCase(createStudentCourses.rejected, (state, action) => {
        state.StudentCoursesShowMessage = true;
        state.StudentCoursesButtonSpinner = false;
        state.StudentCoursesErrors = JSON.parse(action.error.message); // Parse the error messages and store them in the state
      }).addCase(getStudentCourses.pending, (state, action) => {
        state.StudentCoursesButtonSpinner = true;
        state.StudentCoursesTableLoading = true;
      }).addCase(getStudentCourses.fulfilled, (state, action) => {
        state.StudentCoursesButtonSpinner = false;
        state.StudentCoursesTableLoading = false;
        state.StudentCoursesResult = action.payload;
        state.tablePagination = {
          ...state.tablePagination,
          total: action.payload.total,
          current: action.payload.current_page,
          pageSize: action.payload.per_page
        };
      }).addCase(getStudentCourses.rejected, (state, action) => {
        state.StudentCoursesShowMessage = true; // Set the showMessage flag to display the errors
        state.StudentCoursesButtonSpinner = false;
        state.StudentCoursesTableLoading = false;
        state.StudentCoursesErrors = JSON.parse(action.error.message); // Parse the error messages and store them in the state
      }).addCase(getGuardianAllStudentCourses.pending, (state, action) => {
        state.GuardianAllStudentCoursesButtonSpinner = true;
        state.GuardianAllStudentCoursesTableLoading = true;
      }).addCase(getGuardianAllStudentCourses.fulfilled, (state, action) => {
        state.GuardianAllStudentCoursesButtonSpinner = false;
        state.GuardianAllStudentCoursesTableLoading = false;
        state.GuardianAllStudentCoursesResult = action.payload;
        state.tablePagination = {
          ...state.tablePagination,
          total: action.payload.total,
          current: action.payload.current_page,
          pageSize: action.payload.per_page
        };
      }).addCase(getGuardianAllStudentCourses.rejected, (state, action) => {
        state.GuardianAllStudentCoursesShowMessage = true; // Set the showMessage flag to display the errors
        state.GuardianAllStudentCoursesButtonSpinner = false;
        state.GuardianAllStudentCoursesTableLoading = false;
        state.GuardianAllStudentCoursesErrors = JSON.parse(action.error.message); // Parse the error messages and store them in the state
      })

      .addCase(getStudentCoursePlan.pending, (state, action) => {
        state.studentCoursePlanButtonSpinner = true;
        state.studentCoursePlanTableLoading = true;
      }).addCase(getStudentCoursePlan.fulfilled, (state, action) => {
        state.studentCoursePlanResult = action.payload;
        state.studentCoursePlanButtonSpinner = false;
        state.studentCoursePlanTableLoading = false;
      }).addCase(getStudentCoursePlan.rejected, (state, action) => {
        state.studentCoursePlanButtonSpinner = false;
        state.studentCoursePlanTableLoading = false;
      })

      .addCase(viewStudentCourses.pending, (state, action) => {
        state.ViewStudentCoursesLoader = true;
        state.ViewStudentCoursesData = [];
      }).addCase(viewStudentCourses.fulfilled, (state, action) => {
        state.ViewStudentCoursesLoader = false;
        state.ViewStudentCoursesData = action.payload;
      }).addCase(viewStudentCourses.rejected, (state, action) => {
        state.ViewStudentCoursesLoader = false;
        state.ViewStudentCoursesData = [];
      })
      .addCase(deleteStudentCourses.pending, (state, action) => {

      }).addCase(deleteStudentCourses.fulfilled, (state, action) => {

      }).addCase(deleteStudentCourses.rejected, (state, action) => {

      })
      .addCase(getCourseResources.pending, (state, action) => {
      }).addCase(getCourseResources.fulfilled, (state, action) => {
        state.courseResources = action.payload;
      }).addCase(getCourseResources.rejected, (state, action) => {
      });
  },
});

export const {
  setStudentId,
  setStudentData,
  ClassCourseAssignmentDrawerStatus,
  ClassCourseGradeDrawerStatus,
  ClassCourseLessonDrawerStatus,
  ClassCourseScheduleDrawerStatus,
  StudentCoursesAddDrawerStatus,
  StudentCoursesEditWithDrawerStatus,
  updateSortFilters
} = manageStudentCoursesSlice.actions;

export default manageStudentCoursesSlice.reducer;
