import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { LEAVE_APPLICATION, LEAVE_APPLICATION_API_URL, GET_ALL_LEAVE_APPLICATION_API_URL, GET_STUDENT_CLASS } from 'constants/AppConstants';
import { USER_ALL_INFORMATION_ORGANIZATION_WISE } from "constants/AuthConstant";
import CommonService from 'services/CommonService/CommonService';
import IntlMessage from "components/util-components/IntlMessage";
const setLocale = (localeKey, isLocaleOn = true) =>
  isLocaleOn ? <IntlMessage id={localeKey} /> : localeKey.toString();
const permission = JSON.parse(localStorage.getItem(USER_ALL_INFORMATION_ORGANIZATION_WISE));

export const initialState = {
  LeaveApplicationErrors: {},
  permission: permission?.leave_application ?? [],
  LeaveApplicationShowMessage: null,
  LeaveApplicationResult: [],
  StudentListResult: [],
  LeaveApplicationButtonSpinner: false,
  LeaveApplicationTableLoading: true,
  LeaveApplicationAddDrawer: false,
  LeaveApplicationViewDrawer: false,
  LeaveApplicationEditData: [],
  LeaveApplicationButtonAndModelLabel: setLocale('leave_application.add'),
  tablePagination: {
    current: 1,
    pageSize: 10,
    total: 0,
  },
  sorting: {},
  filter: {},
  ViewLeaveApplicationData: [],
  ViewLeaveApplicationLoader: true,
};

export const createLeaveApplication = createAsyncThunk(
  "createLeaveApplication",
  async (data) => {
    try {
      const response = await CommonService.createAndUpdate(data, LEAVE_APPLICATION_API_URL);
      return response;
    } catch (err) {
      throw new Error(JSON.stringify(err.response.data.errors)); // Throw an error with the server response errors
    }
  }
);

export const getLeaveApplication = createAsyncThunk(
  "getLeaveApplication",
  async (data) => {
    try {
      const response = await CommonService.getAllPost(data, GET_ALL_LEAVE_APPLICATION_API_URL);
      return response;
    } catch (err) {
      throw new Error(JSON.stringify(err.response.data.errors)); // Throw an error with the server response errors
    }
  }
);

export const viewLeaveApplication = createAsyncThunk(
  "viewLeaveApplication",
  async (id) => {
    try {
      const response = await CommonService.showOne(id, LEAVE_APPLICATION_API_URL);
      return response;
    } catch (err) {
      throw new Error(JSON.stringify(err.response.data.errors)); // Throw an error with the server response errors
    }
  }
);

export const deleteLeaveApplication = createAsyncThunk(
  "deleteLeaveApplication",
  async (data) => {
    try {
      const response = await CommonService.deleteOne(data, LEAVE_APPLICATION_API_URL);
      return response;
    } catch (err) {
      throw new Error(JSON.stringify(err.response.data.errors)); // Throw an error with the server response errors
    }
  }
);
export const getStudentClass = createAsyncThunk(
  'getStudentClass',
  async (data) => {
    try {
      const response = await CommonService.getAllPost(data, GET_STUDENT_CLASS);
      return response;
    } catch (err) {
      throw new Error(JSON.stringify(err.response.data.errors)); // Throw an error with the server response errors
    }
  }
);

export const manageLeaveApplicationSlice = createSlice({
  name: LEAVE_APPLICATION,
  initialState,
  reducers: {
    LeaveApplicationAddDrawerStatus: (state, action) => {
      if (action.payload === false) {
        state.LeaveApplicationButtonSpinner = false;
      }
      state.LeaveApplicationErrors = {};
      state.LeaveApplicationAddDrawer = action.payload;
      state.LeaveApplicationEditData = [];
      state.LeaveApplicationButtonAndModelLabel = setLocale('leave_application.add');
    },
    LeaveApplicationViewDrawerStatus: (state, action) => {
      state.LeaveApplicationErrors = {};
      state.LeaveApplicationViewDrawer = action.payload;
      state.LeaveApplicationEditData = [];
      state.LeaveApplicationButtonAndModelLabel = setLocale('leave_application.View');
    },
    LeaveApplicationEditWithDrawerStatus: (state, action) => {
      state.LeaveApplicationErrors = {};
      state.LeaveApplicationAddDrawer = true;
      state.LeaveApplicationEditData = action.payload;
      state.LeaveApplicationButtonAndModelLabel = setLocale('leave_application.edit');
    },
    LeaveApplicationViewWithDrawerStatus: (state, action) => {
      state.LeaveApplicationErrors = {};
      state.LeaveApplicationViewDrawer = true;
      state.LeaveApplicationEditData = action.payload;
      state.LeaveApplicationButtonAndModelLabel = setLocale('leave_application.view');
    },
    updateSortFilters: (state, action) => {
      state.filter = action.payload.filter;
      state.sorting = action.payload.sorting;
    },
    setColumnSearch: (state, action) => {
      state.filter = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(createLeaveApplication.pending, (state, action) => {
        state.LeaveApplicationButtonSpinner = true;
      }).addCase(createLeaveApplication.fulfilled, (state, action) => {
        state.LeaveApplicationButtonSpinner = false;
        state.LeaveApplicationErrors = {}
      }).addCase(createLeaveApplication.rejected, (state, action) => {
        state.LeaveApplicationButtonSpinner = false;
        state.LeaveApplicationShowMessage = true;
        state.LeaveApplicationErrors = JSON.parse(action.error.message); // Parse the error messages and store them in the state
      }).addCase(getLeaveApplication.pending, (state, action) => {
        state.LeaveApplicationTableLoading = true;
      }).addCase(getLeaveApplication.fulfilled, (state, action) => {
        state.LeaveApplicationTableLoading = false;
        state.LeaveApplicationResult = action.payload.data;
        state.tablePagination = {
          ...state.tablePagination,
          total: action.payload.pagination.total,
          current: action.payload.pagination.current_page,
          pageSize: action.payload.pagination.per_page
        };
      }).addCase(getLeaveApplication.rejected, (state, action) => {
        state.LeaveApplicationShowMessage = true; // Set the showMessage flag to display the errors
        state.LeaveApplicationTableLoading = false;
        state.LeaveApplicationErrors = JSON.parse(action.error.message); // Parse the error messages and store them in the state
      }).addCase(getStudentClass.fulfilled, (state, action) => {
        state.StudentListResult = action.payload;
      })
      .addCase(viewLeaveApplication.pending, (state, action) => {
        state.ViewLeaveApplicationLoader = true;
        state.ViewLeaveApplicationData = [];
      }).addCase(viewLeaveApplication.fulfilled, (state, action) => {
        state.ViewLeaveApplicationLoader = false;
        state.ViewLeaveApplicationData = action.payload;
      }).addCase(viewLeaveApplication.rejected, (state, action) => {
        state.ViewLeaveApplicationLoader = false;
        state.ViewLeaveApplicationData = [];
      })
      .addCase(deleteLeaveApplication.pending, (state, action) => {

      }).addCase(deleteLeaveApplication.fulfilled, (state, action) => {

      }).addCase(deleteLeaveApplication.rejected, (state, action) => {

      });
  },
});

export const { setColumnSearch, LeaveApplicationAddDrawerStatus, LeaveApplicationViewDrawerStatus, LeaveApplicationEditWithDrawerStatus, LeaveApplicationViewWithDrawerStatus, updateSortFilters } = manageLeaveApplicationSlice.actions;

export default manageLeaveApplicationSlice.reducer;
