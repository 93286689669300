import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import {
  STUDENT_ASSIGNMENT, 
  STUDENT_ASSIGNMENT_API_URL, 
  VIEW_SINGLE_STUDENT_ASSIGNMENT,
  SUBMIT_STUDENT_ASSIGNMENT,
  SAVE_ASSIGNMENT_COMMENT,
  GET_ASSIGNMENT_COMMENTS,
  GET_GUARDIAN_STUDENT_ASSIGNMENTS,
  GET_SINGLE_STUDENT_ASSIGNMENTS
} from "constants/studentAssignment/index";
import { USER_ALL_INFORMATION_ORGANIZATION_WISE } from "constants/AuthConstant";
import CommonService from 'services/CommonService/CommonService';
import IntlMessage from "components/util-components/IntlMessage";
import { signIn } from '../authSlice';
const setLocale = (localeKey, isLocaleOn = true) =>
  isLocaleOn ? <IntlMessage id={localeKey} /> : localeKey.toString();
const permission = JSON.parse(localStorage.getItem(USER_ALL_INFORMATION_ORGANIZATION_WISE));

export const initialState = {
    StudentAssignmentErrors       : {},
    permission: permission?.student_assignment ?? [],
    StudentAssignmentTableLoading : true,
    assignmentComments:[],
    loadingComments:false,
    guardianStudentAssignments: [],
    guardianStudents: [],
    filteredAssignments: [],
    singleStudentAssignments:[],
    singleStudentQuizes:[],
};

export const createStudentAssignment = createAsyncThunk("createStudentAssignment", async (data) => {
    try {
      const response = await CommonService.createAndUpdate(data, STUDENT_ASSIGNMENT_API_URL);
      return response;
    } catch (err) {
      throw new Error(JSON.stringify(err.response.data.errors));
    }
  }
)
export const getGuardianStudentAssignments = createAsyncThunk("getGuardianStudentAssignments", async (data) => {
    try {
      const response = await CommonService.getData(data, GET_GUARDIAN_STUDENT_ASSIGNMENTS);
      return response;
    } catch (err) {
      throw new Error(JSON.stringify(err.response.data.errors)); // Throw an error with the server response errors
    }
  }
)
export const viewStudentAssignment = createAsyncThunk("viewStudentAssignment", async (data) => {
    try {
      return CommonService.getData(data, VIEW_SINGLE_STUDENT_ASSIGNMENT)
    } catch (err) {
      throw new Error(JSON.stringify(err.response.data.errors))
    }
  }
)
export const submitAssignment = createAsyncThunk('submitAssignment', async (data) => {
  try {
     return await CommonService.postData(data,SUBMIT_STUDENT_ASSIGNMENT);
 } catch (err) {
     throw new Error(JSON.stringify(err.response.data.errors))
 }
})
export const saveAssignmentComment = createAsyncThunk('saveAssignmentComment', async (data) => {
  try {
      return await CommonService.postData(data,SAVE_ASSIGNMENT_COMMENT)
  } catch (err) {
      throw new Error(JSON.stringify(err.response.data.errors))
  }
})
export const getAssignmentComments = createAsyncThunk('getAssignmentComments', async (data) => {
  try {
      return await CommonService.getData(data,GET_ASSIGNMENT_COMMENTS)
  } catch (err) {
      throw new Error(JSON.stringify(err.response.data.errors))
  }
})
export const getSingleStudentAssignments = createAsyncThunk("getSingleStudentAssignments", async (data) => {
  try {
    return CommonService.postData(data, GET_SINGLE_STUDENT_ASSIGNMENTS);    
  } catch (err) {
    throw new Error(JSON.stringify(err.response.data.errors))
  }
}
)

export const manageStudentAssignmentSlice = createSlice({
  name: STUDENT_ASSIGNMENT,
  initialState,
  reducers: {
    pushNewComment: (state, action) =>{            
        state.assignmentComments = [action.payload, ...state.assignmentComments];
    },
    setStudentAssignmentLoading: (state, action) => {
      state.StudentAssignmentTableLoading = action.payload;
    },
    setFilteredAssignments: (state, action) => {
      state.filteredAssignments = action.payload;
    },    
  },
  extraReducers: (builder) => {
    builder
      .addCase(createStudentAssignment.pending, (state, action) => {
        state.StudentAssignmentButtonSpinner = true;
      }).addCase(createStudentAssignment.fulfilled, (state, action) => {
        state.StudentAssignmentButtonSpinner = false;
        state.StudentAssignmentErrors = {}
      }).addCase(createStudentAssignment.rejected, (state, action) => {
        state.StudentAssignmentShowMessage = true;
        state.StudentAssignmentButtonSpinner = false;
        state.StudentAssignmentErrors = JSON.parse(action.error.message); // Parse the error messages and store them in the state
      })
      .addCase(getGuardianStudentAssignments.pending, (state, action) => {        
        state.StudentAssignmentTableLoading = true;
      }).addCase(getGuardianStudentAssignments.fulfilled, (state, action) => {        
        state.StudentAssignmentTableLoading = false;
        state.guardianStudentAssignments = action.payload.studentAssignments;  
        state.guardianStudents = action.payload.guardianStudents;      
      }).addCase(getGuardianStudentAssignments.rejected, (state, action) => {        
        state.StudentAssignmentTableLoading = false;      
      })
      .addCase(viewStudentAssignment.pending, (state, action) => {
        state.ViewStudentAssignmentLoader = true;
        state.ViewStudentAssignmentData = [];
      }).addCase(viewStudentAssignment.fulfilled, (state, action) => {
        state.ViewStudentAssignmentLoader = false;
        state.ViewStudentAssignmentData = action.payload.data;
      }).addCase(viewStudentAssignment.rejected, (state, action) => {
        state.ViewStudentAssignmentLoader = false;
        state.ViewStudentAssignmentData = [];
      })
      .addCase(submitAssignment.pending, (state, action) => {
        state.StudentAssignmentButtonSpinner = true;
      }).addCase(submitAssignment.fulfilled, (state, action) => {
        state.StudentAssignmentButtonSpinner = false;
        state.ViewStudentAssignmentData = action.payload.data;
      }).addCase(submitAssignment.rejected, (state, action) => {
        state.StudentAssignmentButtonSpinner = false;
      })
      .addCase(saveAssignmentComment.pending, (state, action) => {                
      }).addCase(saveAssignmentComment.fulfilled, (state, action) => {
        state.StudentAssignmentShowMessage = false
        state.StudentAssignmentButtonSpinner = false
        state.StudentAssignmentTableLoading = false
      }).addCase(saveAssignmentComment.rejected, (state, action) => {
        state.StudentAssignmentShowMessage = true
        state.StudentAssignmentButtonSpinner = false
        state.StudentAssignmentTableLoading = false
        state.StudentAssignmentErrors = JSON.parse(action.error.message)
      })
      .addCase(getAssignmentComments.pending, (state, action) => {
        state.loadingComments = true
        state.assignmentComments = []
      }).addCase(getAssignmentComments.fulfilled, (state, action) => {
          state.loadingComments = false
          state.assignmentComments = action.payload;
      }).addCase(getAssignmentComments.rejected, (state, action) => {
          state.StudentAssignmentErrors = JSON.parse(action.error.message)
          state.StudentAssignmentShowMessage = true
          state.loadingComments = false
      })
      .addCase(getSingleStudentAssignments.pending, (state, action) => {
        state.StudentAssignmentTableLoading = true;
      }).addCase(getSingleStudentAssignments.fulfilled, (state, action) => {
        state.StudentAssignmentTableLoading = false;
        state.singleStudentAssignments = action.payload.assignments
        state.singleStudentQuizes = action.payload.quizes
      }).addCase(getSingleStudentAssignments.rejected, (state, action) => {
        state.StudentAssignmentTableLoading = false;
        state.singleStudentAssignments = [];
        state.singleStudentQuizes = [];
      })

  },
});

export const {  
  pushNewComment,
  setStudentAssignmentLoading,
  setFilteredAssignments
} = manageStudentAssignmentSlice.actions;

export default manageStudentAssignmentSlice.reducer;
